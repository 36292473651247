import React,{Component} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableCell,Paper, TableBody, TableHead, TableRow, Tooltip, Typography, Button, Divider } from '@material-ui/core';
import customStyle from '../../Assets/CSS/stylesheet_UHS';
import Checkbox from '@material-ui/core/Checkbox';
import CommonDropDwn from '../CommonScreens/ProgCommonDropDown';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
const ACSMCheckbox = withStyles({
    root: {
        color: '#533278',
        "&$checked": {
            color: '#533278',
        },
        "&$disabled": {
            color: 'grey'
        }
    },
    checked: {},
    disabled: {
        color: 'grey'
    }
})(Checkbox);

const ProgramCheckbox = withStyles({
    root: {
        color: '#fff',
        "&$checked": {
            color: '#fff',
        },
        "&$disabled": {
            color: 'grey'
        }
    },
    checked: {},
    disabled: {
        color: 'grey'
    }
})(Checkbox);

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#4a4b57',//theme.palette.common.black,
        border: '1px solid #dadde9',
        fontSize : '12px',
        maxWidth : '500px',
        marginLeft:'75px'
    },
    arrow: {
        color: '#4a4b57',
    },
}))(Tooltip);
const HtmlTooltip1 = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#4a4b57',//theme.palette.common.black,
        border: '1px solid #dadde9',
        fontSize : '12px',
        maxWidth : '500px',
        marginRight:'65px'
    },
    arrow: {
        color: '#4a4b57',
    },
}))(Tooltip);
const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: '#4a4b57',//theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: '#4a4b57',//theme.palette.common.black,
        border: '1px solid #dadde9',
        fontSize : '12px'
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

const StyledTableCell = withStyles(theme => (customStyle.tableCell))(TableCell);
const StyledTableCell1 = withStyles(theme => (customStyle.tableCell1))(TableCell);

const StyledTableRow = withStyles(theme => (customStyle.tableRow))(TableRow);


export default  class commonTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rxchecked: [],
            reEnrollTotal:'',
            ProgramList: this.props.programsList,
            program: "UHF",
            planIds: [],
            planNames: [],
        }
    }
    handleAddOnRxSimpleShare(e, data, i){
        let  RxSimpleShare= e.target.checked;
        if(RxSimpleShare){
            this.props.addRxSimpleShare(i);
        }else{
            this.props.removeRxSimpleShare(i);
        }
    }
    componentDidMount(){
        // this.setState({program: this.props.programsList && this.props.programsList[0]})
        if(this.props.reEnroll){
            var reEnrollTotal=parseFloat(this.props.quoteData[this.props.quoteData.length-1].total.replace(/[^\d.-]/g, ''))+20;
            var reEnroll = Number(reEnrollTotal).toFixed(2);
            this.setState({reEnrollTotal:"$"+reEnroll})
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.plans !== this.props.plans && this.props.plans && this.props.plans.length > 0) {
            let primary = this.getMaxValue();
            this.props.plans.map(plan => {
                if (plan.PlanName == primary) {
                    this.setState({
                        planNames: [primary],
                        planIds: [plan.planId],
                    }, () => {
                        this.props.setPlanIds(this.state.planIds);
                        this.props.setPlanNames(this.state.planNames);
                    });
                }
            })
        }
    }

    getMaxValue = () => {
        const amounts = Object.entries(this.props.quoteData[this.props.quoteData.length - 1])
            .filter(([key]) => key !== "name")
            .map(([key, value]) => [key, parseFloat(value.replace("$", ""))]);
        const maxAmountKey = amounts.reduce((maxKey, current) => {
            return current[1] > maxKey[1] ? current : maxKey;
        }, ["", 0])[0];

        return maxAmountKey;
    }

    getValue = (val, itemValid, parentDetails) => {
        if (parentDetails.label === 'program') {
            if (itemValid) {
                this.state.program = val;
                this.props.getQuote(val);
                localStorage.setItem('prgSelected', val)
            } else {
                this.state.program = '';
            }
        }
    };

    selectPlan = (event, row, index) => {
        this.props.plans.map(plan => {
            if (row === plan.PlanName) {
                if (this.state.planIds.includes(plan.planId)) {
                    let ids = this.state.planIds.filter(item => item !== plan.planId);
                    let names = this.state.planNames.filter(item => item !== plan.PlanName);
                    console.log(ids);
                    this.setState({
                        planIds: ids,
                        planNames: names
                    }, () => {
                        this.props.setPlanIds(this.state.planIds);
                        this.props.setPlanNames(this.state.planNames);
                    })
                } else {
                    this.setState({
                        planIds: [...this.state.planIds, plan.planId],
                        planNames: [...this.state.planNames, plan.PlanName],
                    }, () => {
                        this.props.setPlanIds(this.state.planIds);
                        this.props.setPlanNames(this.state.planNames);
                    })
                }
            }
        })
    }

    render() {
        console.log('prog000-- ', this.props.totalReq, ' - - ', this.props.ACSM);
        return (
            
            <Table  style={{minWidth:'350px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>
                <TableHead >
                    <TableRow >
                   


                        {this.props.healthTool ?<StyledTableCell></StyledTableCell>:null}
                        {this.props.RxSimpleShare ?<StyledTableCell></StyledTableCell>:null}
                        {
                            this.props.headerData.map((row,index)=>(
                                index === 0?
                                    <StyledTableCell key={index}>
                                        {
                                            !this.props.noDrop && (this.props.programsList && this.props.programsList[1]) ?
                                            <CommonDropDwn setValue={this.getValue.bind(this)} name={'Prgrams'} label={
                                                this.state.program === "THS"
                                                  ? "Thrive Programs"
                                                  : this.state.program === "Prosper"
                                                  ? "Prosper Programs"
                                                  : this.state.program === "STX"
                                                  ? (
                                                      <>
                                                        ST<sub>x</sub>&nbsp;Programs
                                                      </>
                                                    )
                                                    : this.state.program === "SmartShare"
                                                        ? "UHS SmartShare Programs"
                                                  : "UHS Standard Programs"
                                              } style={{width:'100%',color:"#fff"}} fieldType={'dropDwn'} List={this.props.programsList} isProgram={true} value={this.state.program}  parent={{flag:'program', name:'program', label:'program'}} helperMsg={'Program required'} />
                                            : 
                                            row
                                        }
                                    </StyledTableCell>
                                    :
                                    row == "Surcharge"?
                                    null
                                   :
                                        <StyledTableCell key={index} align="left">
                                            {this.props.plans &&
                                                            <ProgramCheckbox
                                                                checked={this.state.planNames.includes(row)}
                                                                inputProps={{ 'aria-label': 'secondary checkbox', }}
                                                                style={{ marginLeft: '-11px' }}
                                                                onClick={(event => this.selectPlan(event, row, index))}>
                                                            </ProgramCheckbox>
                                                        }
                                            {
                                                row === 'STX1' ?
                                                    <>ST<sub>x</sub>1</> :
                                                    row === 'STX2' ?
                                                        <>ST<sub>x</sub>2</> :
                                                        <>
                                                            {row}
                                                        </>
                                            }
                                        </StyledTableCell>

                            ))

                        }
                    </TableRow>
                </TableHead>
                <TableBody>

                    {this.props.quoteData.map((row,index,key) =>{ 
                        const isSpecialProgram = ["THS", "Prosper", "SSF","STX"].includes(this.state.program);
                        const isLastTwoRows = index === this.props.quoteData.length - 2 || index === this.props.quoteData.length - 1;
                        const isLastRow = index === this.props.quoteData.length - 1;
                        const applyLastRowStyle = this.props.totalReq && this.props.ACSM && !isSpecialProgram
                                                ? isLastTwoRows
                                                : this.props.totalReq
                                                ? isLastRow
                                                : false;
                           return <StyledTableRow key={index} 
                            style = {applyLastRowStyle ? customStyle.lastRow : customStyle.otherRow}>
                               {this.props.healthTool && <StyledTableCell> 
                                   {
                                ((this.props.totalReq && this.props.ACSM) ? (this.props.quoteData.length - 2 === index || this.props.quoteData.length - 1 === index) : this.props.totalReq ? this.props.quoteData.length - 1 === index : false) ?
                                        null : <ACSMCheckbox
                                                checked={this.props.familyTotal}
                                                inputProps={{'aria-label': 'secondary checkbox',}}
                                                style={{ marginLeft:'-11px' }}/>
                                    }
                                    </StyledTableCell> }
                                    {/*for HEalthTool */}
                                {this.props.RxSimpleShare && <StyledTableCell> 
                                   {((this.props.totalReq && this.props.ACSM) ? (this.props.quoteData.length - 2 === index || this.props.quoteData.length - 1 === index) : this.props.totalReq ? this.props.quoteData.length - 1 === index : false) ?
                                        null : <ACSMCheckbox
                                                checked={row.amount !== "-"}
                                                disabled={!this.props.RxEnable}
                                                inputProps={{'aria-label': 'secondary checkbox',}}
                                                style={{ marginLeft:'-11px' }}
                                                onClick ={(event => this.handleAddOnRxSimpleShare(event, row, index))}/>
                                    }
                                    </StyledTableCell> }{/*for RxSimpleShare*/}
                                {

                                    Object.keys(row).map((keyName, i) => (
                                        
                                        i===0 && this.props.quickQuote === false ?

                                            ( <StyledTableCell key={i} component="th" scope="row"  style={customStyle.cellOverflow1}>

                                                { (this.props.totalReq  && index !== this.props.quoteData.length - 1) || (!this.props.totalReq && index !== this.props.quoteData.length ) ?

                                                    <HtmlTooltip disableFocusListener title={row[keyName]} placement='top'>
                                                        
                                                            <span>
                                                                {
                                                                    row[keyName] && Array.isArray(row[keyName]) ?
                                                                        row[keyName].map((key, index) => {
                                                                            return  <span>
                                                                                {key}
                                                                                </span>
                                                                        })
                                                                        :
                                                                        <span>
                                                                            { row[keyName] ? row[keyName].split(/(STX\d)/g).map((part, index) => {
                                                                                if (part.match(/STX(\d)/)) {
                                                                                const digit = part[3]; // Get the digit (1 or 2)
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                    {"ST"}
                                                                                    <sub>x</sub>
                                                                                    {digit}
                                                                                    </React.Fragment>
                                                                                );
                                                                                }
                                                                                return part; // Return non-matching part as text
                                                                            }) : ''}
                                                                        </span>
                                                                }
                                                               </span>
                                                    </HtmlTooltip>

                                                    :
                                                    <span>{row[keyName]}</span>
                                                }
                                            </StyledTableCell>):

                                            i===0 && this.props.quickQuote === true ?
                                                (
                                                    <StyledTableCell1 key={i} component="th" scope="row"  style={ this.props.clientId == '4599' || this.props.clientId == '5742' ? customStyle.cellOverflow65 : customStyle.cellOverflow}>

                                                        { (this.props.totalReq  && index !== this.props.quoteData.length - 1) || (!this.props.totalReq && index !== this.props.quoteData.length ) ?

                                                            <span>
                                                                {

                                                                    row[keyName] && Array.isArray(row[keyName]) ?
                                                                        row[keyName].map((key, index) => {
                                                                            return  <span>{key}</span>
                                                                        })
                                                                        :
                                                                        <span>
                                                                            {row[keyName]}
                                                                            { this.props.quickQuote === true && this.props.tooltip[index] ?

                                                                                <BootstrapTooltip disableFocusListener title={this.props.tooltip[index]} placement='top'>
                                                                                    <InfoRoundedIcon style={{ color : '#4a4b57', marginBottom: "3px", marginLeft: "5px"}} fontSize="small">
                                                                                    </InfoRoundedIcon>
                                                                                </BootstrapTooltip>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                }
                                                               </span>

                                                            :
                                                            <span>
                                                                { row[keyName]}
                                                            </span>


                                                        }
                                                    </StyledTableCell1>
                                                )
                                                :
                                                keyName !="surcharge"?
                                                <StyledTableCell key={i} align="left" style={customStyle.cellOverflow1} >
                                                    {
                                                        row[keyName] && Array.isArray(row[keyName]) ?
                                                            <div style={{display:'flex',flexDirection:'column'}}>
                                                                {
                                                                    row[keyName].map((key, index) => {
                                                                        return <> 
                                                                        <span key={index}>{key}</span>
                                                                         {index < row[keyName].length-1 && <Divider style={{height:'2px'}}/>}
                                                                        </>
                                                                    })
                                                                }
                                                            </div>

                                                            :
                                                            <span>
                                                                {keyName=='surcharge' ?
                                                                null
                                                                :

                                                               keyName=='amount' && this.props.check ==false ?
                                                                '-'
                                                                :
                                                                row[keyName]
                                                            }
                                                            </span>
                                                    }
                                                    {!this.props.quickQuote &&  this.props.tooltip.length > 0 && (this.props.tooltip[index] && this.props.tooltip[index].length > 0) && this.props.headerData.length-1===i && this.props.check &&
                                                    <HtmlTooltip1
                                                        placement='top'
                                                        title={
                                                            <React.Fragment>
                                                                <Typography color="inherit" style={{borderBottom : '1px solid #4a4b57', width : '100%'}}>Surcharges</Typography>
                                                                <div style={{display:'flex',flexDirection:'column'}}>
                                                                    {
                                                                        this.props.tooltip[index] && this.props.tooltip[index].map((key,j)=>{
                                                                            return(
                                                                                <span style={{ marginTop : '5px'}} key={j}>{j+1}. {key}</span>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </React.Fragment>
                                                        }>
                                                        <InfoRoundedIcon style={{ color : '#4a4b57', marginBottom: "3px", marginLeft: "5px"}} fontSize="small"></InfoRoundedIcon>
                                                    </HtmlTooltip1>
                                                    }</StyledTableCell>
                                                    :
                                                    null
                                    ))
                                }
                            </StyledTableRow>
    })}
                    {this.props.reEnroll ? 
                    <StyledTableRow style={{ borderBottom : '2px solid #420045',}}>
                        <StyledTableCell style={{backgroundColor:'#EAE8DB'}}>Monthly Payment</StyledTableCell>
                        <StyledTableCell colSpan={this.props.headerData.length-2} style={{backgroundColor:'#EAE8DB'}}>(Includes UHF monthly membership dues per household of $20)</StyledTableCell>
                        <StyledTableCell>{this.state.reEnrollTotal}</StyledTableCell>
                    </StyledTableRow>: null}
                </TableBody>
            </Table>
            
        )
    }

}


